body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html ::-webkit-scrollbar {
  width: 16px;
  background-color: var(--chakra-colors-gray-50);
}
html ::-webkit-scrollbar-thumb {
  height: 56px;
  border-radius: 8px;
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: #cccccc;
}

/* ripple btn */
.ripple:not([disabled]) {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}
.ripple:not([disabled]):after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.5s, opacity 1s;
}
.ripple:not([disabled]):active:after {
  transform: scale(0, 0);
  opacity: 0.2;
  transition: 0s;
}

.ripple:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* ripple btn */

/* animation router  */

.fade-appear,
.fade-enter {
  opacity: 0;
}

.fade-appear-active,
.fade-enter-active {
  transition: opacity 0.3s linear;
  opacity: 1;
}

.fade-exit {
  transition: opacity 0.2s linear;
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
}
/* animation router  */
/* #nprogress */

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #007bff;
  z-index: 2147483647;
  top: -65px;
  left: 0;
  width: 100%;
  height: 5px;
  position: absolute;
}

#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #007bff, 0 0 5px #007bff;
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0, -4px);
  -ms-transform: rotate(3deg) translate(0, -4px);
  transform: rotate(3deg) translate(0, -4px);
}

#nprogress .spinner {
  position: absolute;
  display: block;
  z-index: 2147483647;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: #007bff;
  border-left-color: #007bff;
  border-radius: 50%;
  -webkit-animation: nprogress-spinner 0.4s linear infinite;
  animation: nprogress-spinner 0.4s linear infinite;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* #nprogress */

.availability-calendar {
  width: 50vw;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.availability-calendar--doubleView {
  width: 700px;
}
.availability-calendar--doubleView .availability-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.availability-calendar--doubleView .availability-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.availability-calendar,
.availability-calendar *,
.availability-calendar *:before,
.availability-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.availability-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.availability-calendar button:enabled:hover {
  cursor: pointer;
}
.availability-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.availability-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.availability-calendar__navigation button:enabled:hover,
.availability-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.availability-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.availability-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.availability-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.availability-calendar__month-view__weekNumbers .availability-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.availability-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.availability-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.availability-calendar__year-view .availability-calendar__tile,
.availability-calendar__decade-view .availability-calendar__tile,
.availability-calendar__century-view .availability-calendar__tile {
  padding: 2em 0.5em;
}
.availability-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 2.75em 0.5em;
  background: var(--chakra-colors-green-500);
}
.indicator {
  width: 50px;
  height: 50px;
}

.availability-calendar__tile:disabled,
.availability-calendar__tile[is-ghost-disabled="yes"],
.no-availabile-indicator {
  background-color: var(--chakra-colors-red-300);
}

.availabile-indicator {
  background-color: var(--chakra-colors-green-500);
}

.availability-calendar__tile[is-ghost-disabled="yes"]:enabled:hover {
  background-color: var(--chakra-colors-green-500);
}

.availability-calendar__tile[is-ghost-disabled="no"]:enabled:hover {
  background-color: var(--chakra-colors-red-300);
}

.availability-calendar__tile[is-ghost-disabled="yes"]:enabled:focus {
  background-color: var(--chakra-colors-red-300);
}

.availability-calendar__tile[is-ghost-disabled="no"]:enabled:focus {
  background-color: var(--chakra-colors-green-500);
}

.availability-calendar__tile--now,
.today-indicator {
  /* background: var(--chakra-colors-yellow-300); */
  border: 5px solid var(--chakra-colors-yellow-300) !important;
}
.availability-calendar__tile--now:enabled:hover,
.availability-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.availability-calendar__tile--hasActive {
  background: #76baff;
}
.availability-calendar__tile--hasActive:enabled:hover,
.availability-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.availability-calendar__tile--active,
.selected-indicator {
  /* background: var(--chakra-colors-purple-500); */
  color: white;
}
.availability-calendar__tile--active:enabled:hover,
.availability-calendar__tile--active:enabled:focus,
.active-indicator {
  /* background: var(--chakra-colors-blue-500); */
}
.availability-calendar--selectRange .availability-calendar__tile--hover {
  background-color: #e6e6e6;
}
